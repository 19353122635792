.root {
  --bs-accordion-btn-icon-custom: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-icon-custom-transition: transform 0.2s ease-in-out;

  display: flex;
  flex-flow: row wrap;
}

.root>h1,
.root p {
  color: var(--color);
}

/* компоновка контента */
.root>div:nth-child(1) {
  flex: 1 200px;
  margin: 0 15px 15px 0;
  max-width: 300px; /* ограничить растяжение блока */
}

.root>div:nth-child(2) {
  flex: 5 500px;
}

/* фиксация ширины кнопки */
.root>div:nth-child(1)>input[type=submit] {
  width: 250px;
 }

