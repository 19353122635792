.root{
  margin-top: 50px;
}

.root li {
  color: var(--color);
}

.svg {
  width: 16px;
  fill: var(--color);
  opacity: 0.8;

}

.svg:hover{
  /* cursor: pointer;
  opacity: 1; */
}