.root {
  margin-top: 70px;
  display: flex;
  flex-flow: row wrap;
}

.root>div:nth-child(1) {
  flex: 2 300px;
  max-width: 280px; /* ограничить растяжение блока */
}

.root>div:nth-child(2) {
  flex: 2 500px;
  max-width: 800px; /* ограничить растяжение блока */
}

.root h1,
.root h2,
.root h3,
.root h4,
.root h5,
.root h6,
.root p,
.root a,
.root b {
  color: var(--color);
}

.root a:hover{
  color: var(--color);
  text-decoration: none;
}